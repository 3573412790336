import { Observable } from "rxjs";
import { ListFaqQuery, ListFaqResponse } from "../dto/list-faq.dto";
import { WriteFAQPayload, WriteFAQResponse } from "../dto/write-faq.dto";
import { ShowFAQResponse } from "../dto/read-faq.dto";

export abstract class FaqRepository {

  abstract show(payload: number): Observable<ShowFAQResponse>;

  abstract list(query?: ListFaqQuery): Observable<ListFaqResponse>;

  abstract create(payload: WriteFAQPayload): Observable<WriteFAQResponse>;

  abstract update(payload: WriteFAQPayload): Observable<WriteFAQResponse>;

  abstract delete(payload: number): Observable<any>;

  abstract sortByIds(payload: number[]): Observable<any>;

}
